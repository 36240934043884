import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Link } from 'gatsby';
import Layout from '../../components/Layout/layout';
import SEO from '../../components/seo';
import CtaSecondary from '../../components/Buttons/secondary-cta';
import ogImage from '../../images/global_assets/og-image.png';
import LegalStyles from '../../components/Layout/LegalStyles';

const { Title } = Typography;

const MerchantTerms = () => {
  return (
    <Layout className="consumer-privacy">
      <SEO
        title="SpotOn | Privacy &amp; Legal"
        description="At SpotOn, we respect your privacy and are committed to being upfront and transparent. Please review our terms of services and privacy policies for more information."
        image={`https://spoton.com/${ogImage}`}
      />
      {/* <Affix offsetTop={0}> */}
      <div style={{ marginTop: 25 }}>
        <CtaSecondary target="/legal" ctaTitle="Legal" reverseIcon />
      </div>
      {/* </Affix> */}
      <main className="consumer-privacy__data">
        <Row>
          <Col lg={16} sm={24}>
            <div style={{ marginBottom: 80 }}>
              <Title level={1}>SpotOn’s SMS Terms &amp; Conditions</Title>
              <p>
                Text <b>SIGNUP</b> to 81314 to join SpotOn’s account/service
                changes, new product offerings, and promotion Alerts. 4msgs/mo.
                Reply
                <b>HELP</b> for help, <b>STOP</b> to cancel. Msg&amp;Data Rates
                May Apply.
              </p>
              <p>
                Compatible carriers include: AT&amp;T, T-Mobile®, Verizon
                Wireless, Sprint, Boost, U.S. Cellular, Cellular One, MetroPCS,
                ACS/Alaska, Bluegrass Cellular, Cellular One of East Central
                Illinois, Centennial Wireless, Cox Communications,
                EKN/Appalachian Wireless, GCI, Illinois Valley Cellular,
                Immix/Keystone Wireless, Inland Cellular, Nex-Tech Wireless,
                Rural Cellular Corporation, Thumb Cellular, United Wireless,
                West Central (WCC), Cellcom, Cellsouth, Cricket, Cincinnati Bell
                and Virgin Mobile.
              </p>
              <p>
                T-Mobile® is not liable for delayed or undelivered messages.
              </p>
              <p>
                To discontinue receiving SMS messages from SpotOn’s
                account/service changes, new product offerings, and promotion
                Alerts, text <b>STOP</b> to 81314.
              </p>
              <p>
                For additional help, text <b>HELP</b> to 81314 or contact
                <a href="mailto: support@spoton.com"> support@spoton.com</a> or
                call <a href="tel: +18778144102">(877) 814-4102</a>. SpotOn
                respects your right to privacy. You can view{' '}
                <Link to="/legal/consumer-privacy">
                  our privacy policy here
                </Link>
                .
              </p>
            </div>
          </Col>
          <Col lg={8} sm={24}>
            <div className="aside">
              <Title level={3}>Related Documents</Title>
              <CtaSecondary
                target="/legal/consumer-privacy"
                ctaTitle="Consumer Privacy"
              />
              <CtaSecondary
                target="/legal/merchant-privacy"
                ctaTitle="Merchant Privacy"
              />
              <CtaSecondary
                target="/payments-terms.pdf"
                ctaTitle="Payments Terms"
              />
              <CtaSecondary 
                target="/legal/user-terms" 
                ctaTitle="User Terms" 
              />
              <CtaSecondary
                target="/legal/point-of-sale-terms"
                ctaTitle="Point-of-Sale Terms"
              />
              <CtaSecondary
                target="/legal/website-terms"
                ctaTitle="Website Terms"
              />
              <CtaSecondary
                target="/legal/reserve-terms"
                ctaTitle=" Reserve Terms"
              />
               <CtaSecondary
                target="/legal/teamwork-terms"
                ctaTitle="SpotOn Teamwork Terms"
              />
            </div>
          </Col>
        </Row>
      </main>

      <LegalStyles />
    </Layout>
  );
};

export default MerchantTerms;
